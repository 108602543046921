import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [7,2];

export const dictionary = {
		"/": [8],
		"/(auth)/change-password": [26,[7]],
		"/(auth)/confirm-email": [27,[7]],
		"/(auth)/forgot-password": [28,[7]],
		"/(auth)/login": [29,[7]],
		"/(auth)/logout": [30,[7]],
		"/(app)/(with-layout)/privacy": [9,[2,3]],
		"/(auth)/register": [31,[7]],
		"/(app)/(with-layout)/secure": [10,[2,3,4]],
		"/(app)/(with-layout)/secure/brandhub": [11,[2,3,4]],
		"/(app)/(with-layout)/secure/demo": [12,[2]],
		"/(app)/(with-layout)/secure/favourites": [13,[2,3,4]],
		"/(app)/(with-layout)/secure/image": [14,[2,3,4,5]],
		"/(app)/(with-layout)/secure/image/generation": [15,[2,3,4,5,6]],
		"/(app)/(with-layout)/secure/image/generation/preset": [16,[2,3,4,5,6]],
		"/(app)/(with-layout)/secure/image/generation/prompt": [17,[2,3,4,5,6]],
		"/(app)/(with-layout)/secure/image/swap": [18,[2,3,4,5]],
		"/(app)/(with-layout)/secure/plans": [19,[2,3,4]],
		"/(app)/(with-layout)/secure/profile": [20,[2,3,4]],
		"/(app)/(with-layout)/secure/profile/manage-subscription": [~21,[2,3,4]],
		"/(app)/(with-layout)/secure/synt": [22,[2,3,4]],
		"/(app)/(with-layout)/secure/synt/draft": [23,[2,3,4]],
		"/(app)/(with-layout)/secure/synt/draft/[id]": [24,[2,3,4]],
		"/(app)/(with-layout)/terms": [25,[2,3]],
		"/[...route]": [32]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';